<template>
  <div class="relative mt-5 w-full max-w-full px-4 sm:hidden">
    <swiper
      :modules="[Autoplay, FreeMode]"
      :slides-per-view="'auto'"
      :space-between="50"
      :free-mode="true"
      :loop="true"
      :autoplay="{
        delay: 1,
        disableOnInteraction: false
      }"
      :speed="4000"
      class="mySwiper"
    >
      <swiper-slide v-for="brand in brands" :key="brand.name" class="w-auto">
        <a :href="brand.href" target="_blank" rel="noopener noreferrer">
          <component :is="brand.icon" v-bind="brand" :class="brand.className" />
        </a>
      </swiper-slide>
    </swiper>
  </div>
  <div class="hidden w-full max-w-full px-4 sm:block">
    <div
      class="flex min-w-0 flex-row items-center justify-center gap-8 overflow-x-auto sm:flex-wrap sm:gap-16 sm:overflow-auto"
    >
      <div v-for="brand in brands" :key="brand.name" class="flex-shrink-0 cursor-pointer">
        <a :href="brand.href" target="_blank" rel="noopener noreferrer">
          <component :is="brand.icon" v-bind="brand" :class="brand.className" />
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, FreeMode } from 'swiper/modules'
import 'swiper/css'

import DronIcon from '@public/brands/dron-logo.svg'
import GeberitIcon from '@public/brands/geberit-logo.svg'
import GiroIcon from '@public/brands/gira.svg'
import GroheIcon from '@public/brands/grohe.svg'
import VilleroyIcon from '@public/brands/villeroy-logo.svg'

const brands = [
  {
    name: 'Villeroy & Boch',
    icon: VilleroyIcon,
    href: 'https://www.villeroy-boch.de/',
    className: 'fill-[#053e84] h-10 sm:h-14 brand-icon'
  },
  {
    name: 'hansgrohe',
    icon: GroheIcon,
    href: 'https://www.hansgrohe.de/',
    className: 'fill-[#26a792] h-4  brand-icon '
  },
  {
    name: 'Dornbracht',
    icon: DronIcon,
    href: 'https://www.dornbracht.com/de-de/',
    className: 'h-10 sm:h-14 brand-icon'
  },
  {
    name: 'gira',
    icon: GiroIcon,
    href: 'https://www.gira.de/',
    className: 'h-4   brand-icon'
  },
  {
    name: 'geberit',
    icon: GeberitIcon,
    href: 'https://www.geberit.de/',
    className: 'h-4  brand-icon'
  }
]
</script>

<style>
.swiper-slide {
  width: auto !important;
}
</style>
